.treelist {
    --spacing: 1.5rem;
    --radius: 10px;
  }
  
  .treelist li {
    width: 100%;
    display: block;
    position: relative;
    padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
  }
  
  .treelist ul {
    margin-left: calc(var(--radius) - var(--spacing));
    padding-left: 0;
  }
  
  .treelist ul li {
    border-left: 2px solid #ddd;
    margin-bottom:10px
  }
  
  .treelist ul li:last-child {
    border-color: transparent;
  }
  
  .treelist ul li::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--spacing) / -2);
    left: -2px;
    width: calc(var(--spacing) + 2px);
    height: calc(var(--spacing) + 1px);
    border: solid #ddd;
    border-width: 0 0 2px 2px;
  }
  
  .treelist summary {
    display: block;
    cursor: pointer;
    margin-bottom:10px
  }
  
  .treelist summary::marker,
  .treelist summary::-webkit-details-marker {
    display: none;
  }
  
  .treelist summary:focus {
    outline: none;
  }
  
  .treelist summary:focus-visible {
    outline: 1px dotted #000;
  }
  
  .treelist li::after,
  .treelist summary::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--spacing) / 2 - var(--radius));
    left: calc(var(--spacing) - var(--radius) - 1px);
    width: calc(2 * var(--radius));
    height: calc(2 * var(--radius));
    border-radius: 50%;
    background: #ddd;
  }
  
  .treelist summary::before {
    z-index: 1;
    background: #696 url('data:image/svg+xml,<%3Fxml version="1.0"%3F><svg xmlns="http://www.w3.org/2000/svg" width="40" height="20"><g fill="%23fff"><path d="m5 9h4v-4h2v4h4v2h-4v4h-2v-4h-4z"/><path d="m25 9h10v2h-10z"/></g></svg>') 0 0;
  }
  
  .treelist details[open] > summary::before {
    background-position: calc(-2 * var(--radius)) 0;
  }