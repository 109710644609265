* {
  scrollbar-color: #00065e transparent;
  scrollbar-width: thin;
}

body {
  /*background: #f5f5f9 !important;*/

  /*
    background: #B2FFFF !important;
  */
  /*
    background-image: url(img/a33afc1b-3acb-4a84-9a46-df2858e60424.jpg);
    background-image: url(img/13777983_gradient_2.jpg);
  */
  background-size: cover;
  background-color: #efefef !important;
  background-attachment: fixed;
  /*
  background-image:url(https://img.freepik.com/free-vector/grey-abstract-background_1128-55.jpg?t=st=1739288384~exp=1739291984~hmac=8918187a5cb969fcfdf1bab6fb35f2606effe80d27fd9bdcabf0c93648250c2d&w=826);
  */
  font-size: 1rem;
}
.css-izap9d-MuiTypography-root{
  font-size: 0.85rem;
}
.law-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  width: fit-content;
  max-width: calc(100% - 10px);
  background-color: #fff;
  border-radius: 10px;
  max-height: 95vh;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .law-modal {
    min-width: 300px;
  }
}

.law-modal .law-modal-header {
  display: grid;
  grid-template-columns: 1fr 40px;
  width: 100%;
  padding: 5px 10px;
  margin: 0;
  gap: 5px;
  align-content: center;
  align-items: center;
  justify-items: stretch;
  background: rgb(0, 6, 94);
  box-shadow: 0px 8px 14px -10px #cccccc;
}

.law-modal .law-modal-header * {
  color: #fff !important;
}

.law-modal .law-modal-header button {
  background-color: transparent !important;
  box-shadow: none !important;
}

.law-modal .law-modal-header button svg {
  fill: #fff !important;
}

.law-modal .law-modal-header h5 {
  margin: 0;
}

.law-modal .law-modal-body {
  display: block;
  height: auto;
  max-height: calc(100vh - 100px);
  overflow: auto;
  padding: 1rem 15px 3rem 15px;
}

.ac-card {
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  padding: 2rem 1rem;
  background: #ffffff;
  backdrop-filter: blur(10px);
}

.ac-single-chart {
  width: 42px;
  float: left;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  padding: 0px;
}

.ac-circular-chart {
  display: block;
  margin: 0px auto;
  max-width: 80%;
  max-height: 24px;
}

.ac-circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 7px;
}

.ac-circle {
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.ac-circular-chart.primary .ac-circle {
  stroke: #696cff;
}

.ac-circular-chart.warning .ac-circle {
  stroke: #ffab00;
}

.ac-circular-chart.danger .ac-circle {
  stroke: #ff3e1d;
}

.ac-circular-chart.success .ac-circle {
  stroke: #71dd37;
}

.ac-circular-chart.info .ac-circle {
  stroke: #03c3ec;
}

.ac-circular-chart.secondary .ac-circle {
  stroke: #788393;
}

.ac-percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 1em;
  text-anchor: middle;
  background-color: yellow;
}

/**********************************************************/

.finbyz-timeline {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.wgl-row-animation {
  position: relative;
  z-index: 1;
  margin: auto;
}

.wgl-row-animation {
  position: relative;
  z-index: 1;
}

.wgl-row-animation .wpb_column {
  position: relative;
  z-index: 2;
}

.wgl-row-animation .wgl-row_background {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 70%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.seofy_module_time_line_vertical {
  position: relative;
  z-index: 1;
  padding: 70px 0;
}

.seofy_module_time_line_vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 2px;
  left: 50%;
  top: 0;
  margin-left: -1px;
  background: -webkit-linear-gradient(top, transparent 0%, rgba(63, 71, 103, 0.15) 100px, rgba(63, 71, 103, 0.15) -webkit-calc(100% - 100px), transparent 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(63, 71, 103, 0.15) 100px, rgba(63, 71, 103, 0.15) -moz-calc(100% - 100px), transparent 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(63, 71, 103, 0.15) 100px, rgba(63, 71, 103, 0.15) calc(100% - 100px), transparent 100%);
}

.seofy_module_time_line_vertical.appear_anim .time_line-item .time_line-date {
  -webkit-transform: translateX(-20%);
  -moz-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  -o-transform: translateX(-20%);
  transform: translateX(-20%);
  opacity: 0;
  margin: 0;
}

.seofy_module_time_line_vertical.appear_anim .time_line-item .seofy_hexagon {
  opacity: 0;
}

.seofy_module_time_line_vertical.appear_anim .time_line-item .time_line-content {
  max-width: calc(100vw - 170px);
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  -o-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
}

/*
.seofy_module_time_line_vertical.appear_anim
  .time_line-item:nth-child(even)
  .time_line-date {
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  -o-transform: translateX(20%);
  transform: translateX(20%);
}
*/
.seofy_module_time_line_vertical.appear_anim .time_line-item:nth-child(even) .time_line-content {
  -webkit-transform: translateX(-20%);
  -moz-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  -o-transform: translateX(-20%);
  transform: translateX(-20%);
}

.seofy_module_time_line_vertical .time_line-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.seofy_module_time_line_vertical .time_line-item:last-child {
  margin-bottom: 0;
}

.seofy_module_time_line_vertical .time_line-item:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.seofy_module_time_line_vertical .time_line-item:nth-child(even) .time_line-date_wrap {
  margin-right: -webkit-calc(50% - 10px);
  margin-right: -moz-calc(50% - 10px);
  margin-right: calc(50% - 10px);
  margin-left: 20px;
}

.seofy_module_time_line_vertical .time_line-item:nth-child(even) .time_line-date {
  right: auto;
  left: 100%;
  padding-left: 50px;
  padding-right: 0;
}

.seofy_module_time_line_vertical .time_line-item:nth-child(even) .time_line-image {
  left: 0;
  right: auto;
}

.seofy_module_time_line_vertical .time_line-item:nth-child(even) .time_line-content {
  text-align: right;
}

.seofy_module_time_line_vertical .time_line-item:nth-child(even) .time_line-content:before {
  left: auto;
  right: 0;
}

.seofy_module_time_line_vertical .seofy_hexagon {
  position: absolute;
  top: 0;
  z-index: -1;
}

.seofy_module_time_line_vertical .time_line-item:nth-child(even) .seofy_hexagon {
  left: auto;
  right: 0;
}

.seofy_module_time_line_vertical .time_line-item.item_active .seofy_hexagon:nth-child(2) {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
}

.seofy_module_time_line_vertical .time_line-item.item_show .time_line-content,
.seofy_module_time_line_vertical .time_line-item.item_show:nth-child(even) .time_line-content {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.seofy_module_time_line_vertical .time_line-item.item_show .seofy_hexagon,
.seofy_module_time_line_vertical .time_line-item.item_show:nth-child(even) .seofy_hexagon {
  opacity: 1;
}

.seofy_module_time_line_vertical .time_line-item.item_show .time_line-date,
.seofy_module_time_line_vertical .time_line-item.item_show:nth-child(even) .time_line-date {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.seofy_module_time_line_vertical .time_line-date_wrap {
  position: relative;
  z-index: 1;
  margin-left: -webkit-calc(50% - 10px);
  margin-left: -moz-calc(50% - 10px);
  margin-left: calc(50% - 10px);
  margin-right: 20px;
}

.seofy_module_time_line_vertical .seofy_hexagon {
  height: 22px;
  width: 20px;
  -webkit-transition: opacity 0.8s;
  -o-transition: opacity 0.8s;
  -moz-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.seofy_module_time_line_vertical .seofy_hexagon:first-child {
  position: relative;
  z-index: 0;
}

.seofy_module_time_line_vertical .seofy_hexagon:nth-child(2) svg {
  fill: #ffffff !important;
}

.seofy_module_time_line_vertical .time_line-date {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 100%;
  padding-right: 50px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.seofy_module_time_line_vertical .time_line-content {
  position: relative;
  z-index: 1;
  padding: 23px 30px;
  width: 100%;
  max-width: 345px;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  -moz-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}

.seofy_module_time_line_vertical .time_line-content:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.seofy_module_time_line_vertical .time_line-image {
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
}

.seofy_module_time_line_vertical .time_line-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 0;
}

.seofy_module_time_line_vertical .time_line-descr {
  line-height: 24px;
}

.seofy_module_time_line_vertical .time_line-item:hover .time_line-content {
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
  transform: translateX(5px);
  background: white;
}

.time_line-content {
  width: 100%;
  max-width: 500px !important;
}

.seofy_module_time_line_vertical .time_line-item:hover .time_line-date {
  -webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  -o-transform: translateX(-5px);
  transform: translateX(-5px);
}

.seofy_module_time_line_vertical .time_line-item:hover:nth-child(even) .time_line-content {
  -webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  -o-transform: translateX(-5px);
  transform: translateX(-5px);
}

.seofy_module_time_line_vertical .time_line-item:hover:nth-child(even) .time_line-date {
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
  transform: translateX(5px);
}

.seofy_module_time_line_vertical .time_line-item:hover.item_active .seofy_hexagon:nth-child(2) {
  -webkit-animation: pulse_hexagon_active 2s infinite;
  -moz-animation: pulse_hexagon_active 2s infinite;
  -o-animation: pulse_hexagon_active 2s infinite;
  animation: pulse_hexagon_active 2s infinite;
}

.seofy_module_time_line_vertical .time_line-item:hover .seofy_hexagon:nth-child(2) {
  -webkit-animation: pulse_hexagon 2s infinite;
  -moz-animation: pulse_hexagon 2s infinite;
  -o-animation: pulse_hexagon 2s infinite;
  animation: pulse_hexagon 2s infinite;
}

@-webkit-keyframes pulse_hexagon {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  70% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes pulse_hexagon {
  0% {
    -moz-transform: scale(1);
    transform: scale(1);
  }

  70% {
    -moz-transform: scale(2);
    transform: scale(2);
  }

  100% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes pulse_hexagon {
  0% {
    -o-transform: scale(1);
    transform: scale(1);
  }

  70% {
    -o-transform: scale(2);
    transform: scale(2);
  }

  100% {
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse_hexagon {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  70% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes pulse_hexagon_active {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }

  30% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@-moz-keyframes pulse_hexagon_active {
  0% {
    -moz-transform: scale(2);
    transform: scale(2);
  }

  30% {
    -moz-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -moz-transform: scale(2);
    transform: scale(2);
  }
}

@-o-keyframes pulse_hexagon_active {
  0% {
    -o-transform: scale(2);
    transform: scale(2);
  }

  30% {
    -o-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -o-transform: scale(2);
    transform: scale(2);
  }
}

@keyframes pulse_hexagon_active {
  0% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
  }

  30% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
  }
}

.time_line-item.warning svg {
  fill: #ffa705;
}

.time_line-item.warning .time_line-date {
  color: #ffa705;
}

.time_line-item.warning .time_line-content:before {
  background: #ffa705;
}

.time_line-item.danger svg {
  fill: #ff5600;
}

.time_line-item.danger .time_line-date {
  color: #ff5600;
}

.time_line-item.danger .time_line-content:before {
  background: #ff5600;
}

.time_line-item.info svg {
  fill: #69e9f2;
}

.time_line-item.info .time_line-date {
  color: #69e9f2;
}

.time_line-item.info .time_line-content:before {
  background: #69e9f2;
}

.time_line-item.secondary svg {
  fill: #12affe;
}

.time_line-item.secondary .time_line-date {
  color: #12affe;
}

.time_line-item.secondary .time_line-content:before {
  background: #12affe;
}

.time_line-item.primary svg {
  fill: #3224e9;
}

.time_line-item.primary .time_line-date {
  color: #3224e9;
}

.time_line-item.primary .time_line-content:before {
  background: #3224e9;
}

@media only screen and (max-width: 1048px) {
  .seofy_module_time_line_vertical:before {
    left: 45px;
  }

  .seofy_module_time_line_vertical .time_line-date_wrap {
    margin-left: 35px !important;
    margin-right: 15px !important;
  }

  .seofy_module_time_line_vertical .time_line-date {
    padding: 0 !important;
    right: 100% !important;
    left: auto !important;
  }

  .seofy_module_time_line_vertical .time_line-content {
    text-align: left !important;
  }

  .seofy_module_time_line_vertical .time_line-content:before {
    left: 0 !important;
    right: auto !important;
  }

  .seofy_module_time_line_vertical .time_line-item {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -moz-box-orient: horizontal !important;
    -moz-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .seofy_module_time_line_vertical .time_line-date {
    -webkit-transform: rotate3d(0, 0, 1, -90deg) !important;
    -moz-transform: rotate3d(0, 0, 1, -90deg) !important;
    transform: rotate3d(0, 0, 1, -90deg) !important;
  }

  .seofy_module_time_line_vertical.appear_anim .time_line-date {
    -webkit-transform: translateX(-20%) rotate3d(0, 0, 1, -90deg) !important;
    -moz-transform: translateX(-20%) rotate3d(0, 0, 1, -90deg) !important;
    transform: translateX(-20%) rotate3d(0, 0, 1, -90deg) !important;
  }

  .seofy_module_time_line_vertical.appear_anim .time_line-content {
    -webkit-transform: translateX(20%) !important;
    -moz-transform: translateX(20%) !important;
    -ms-transform: translateX(20%) !important;
    -o-transform: translateX(20%) !important;
    transform: translateX(20%) !important;
  }

  .seofy_module_time_line_vertical.appear_anim .time_line-item.item_show .time_line-date {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translate(15px, -9px) rotate3d(0, 0, 1, -90deg) !important;
    -moz-transform: translate(15px, -9px) rotate3d(0, 0, 1, -90deg) !important;
    transform: translate(15px, -9px) rotate3d(0, 0, 1, -90deg) !important;
    width: 100px;
    white-space: normal;
  }

  .seofy_module_time_line_vertical.appear_anim .time_line-item.item_show .time_line-content {
    -webkit-transform: translateX(0) !important;
    -moz-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    -o-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }

  .seofy_module_time_line_vertical .time_line-item:hover .time_line-content {
    -webkit-transform: translateX(5px) !important;
    -moz-transform: translateX(5px) !important;
    -ms-transform: translateX(5px) !important;
    -o-transform: translateX(5px) !important;
    transform: translateX(5px) !important;
  }

  .seofy_module_time_line_vertical .time_line-item.item_show:hover .time_line-content {
    -webkit-transform: translateX(5px) !important;
    -moz-transform: translateX(5px) !important;
    -ms-transform: translateX(5px) !important;
    -o-transform: translateX(5px) !important;
    transform: translateX(5px) !important;
  }
}

.seofy_module_time_line_vertical.appear_anim .time_line-item .seofy_hexagon svg {
  filter: drop-shadow(4px 5px 4px rgba(0, 0, 0, 0.1));
}

/***********************************************************/
.law-message-body {
  width: 100%;
  display: block;
  height: calc(100vh - 317px);
  overflow: auto;
  background: #f3f3f3;
  padding: 20px;
  position: relative;
}

.law-message-body .item {
  width: 100%;
  display: flex;
  min-height: 20px;
  margin-bottom: 20px;
}


.law-message-body .item-my {
  justify-content: end !important;
}

.law-message-body .item>* {
  max-width: 400px;
  padding: 20px 10px;
  width: 90%;
  background-color: #ddd;
  border-radius: 15px;
  min-height: 100px;
}

.law-message-body .item p {
  margin: 0;
  margin-bottom: 20px;
}

.law-message-body .item span {
  font-size: 0.9rem;
  width: 100%;
  display: block;
  text-align: right;
}

.law-message-body .item .sender {
  text-align: left;
  color: #0077c7;
}

.law-message-selected-users {
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -70px;
  display: flex;
  justify-content: start;
  align-items: center;
  align-content: center;
  overflow: auto;
}

.law-message-selected-users button {
  min-width: 100px;
  background-color: #fff !important;
  border-radius: 10px;
  margin: 5px;
}

#law-message-textarea {
  width: 100%;
  border: none;
  resize: none;
  padding: 5px 10px;
}

#law-message-textarea:focus {
  outline: none;
  background: #eee;
}




.gallry-container {
  min-height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2rem 2rem;

}

.gallry-container *,
.gallry-container *::before,
.gallry-container *::after {
  box-sizing: border-box;
}

.gallry-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1rem;
}

.gallry-items {
  flex: 1 0 24rem;
  max-width: 200px;
  margin: 1rem;
  overflow: hidden;
  position: relative;
}

.gallry-images {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1 !important;
  transition: transform 400ms ease-out;
}

.gallry-images:hover {
  transform: scale(1.15);
}

@supports (display: grid) {
  .gallry-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 0.5rem;
  }

  .gallry-gallery,
  .gallry-items {
    margin: 0;
  }
}

.law-navbar-notifications {
  width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 10px;
}

.law-navbar-notifications .law-data-not-found * {
  color: #fff
}

.law-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 270px));
  gap: 15px;
  padding: 0px;
  justify-content: start;
  /* İçeriği yatayda ortalar */
}

#ldng-anm-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
}

.ldng-anm-load>* {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 3px;
  border-radius: 15px;
  background-color: white;
}

.ldng-anm-load>*:nth-last-child(1) {
  animation: ldng-anmation 0.6s 0.1s linear infinite;
}

.ldng-anm-load>*:nth-last-child(2) {
  animation: ldng-anmation 0.6s 0.2s linear infinite;
}

.ldng-anm-load>*:nth-last-child(3) {
  animation: ldng-anmation 0.6s 0.3s linear infinite;
}

.ldng-anm-load>*:nth-last-child(4) {
  animation: ldng-anmation 0.6s 0.4s linear infinite;
}

@keyframes ldng-anmation {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.props-list-container {
  max-width: 800px;
  margin: auto
}

.props-list-container img {
  width: 480px;
}

.props-list-container * {
  color: #444444;
  text-transform: capitalize;
}

.props-list-container h3 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  display: block;
  text-align: center !important;
  width: 100%
}

.props-list-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.props-list-container li {
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 5px;
  opacity: 0;
  text-align: center;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-in forwards;

}

.props-list-container li span {
  font-size: 21px;
  font-weight: 600
}

.props-list-container li:nth-child(1) {
  animation-delay: 0.5s;
}

.props-list-container li:nth-child(2) {
  animation-delay: 0.9s;
}

.props-list-container li:nth-child(3) {
  animation-delay: 1.3s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.law-bg-blur {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.notebook-grid {
  background: linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px), linear-gradient(rgba(0, 0, 0, .04) 1px, transparent 1px), #f2f2f2;
  background-size: 20px 20px, 20px 20px, 80px 80px, 80px 80px, 80px 80px, 80px 80px, 80px 80px, 80px 80px;
  background-color: #fff;
}

.grd2-container {
  width: 100%;
  display: grid;
  height: calc(100vh - 170px);
  grid-template-columns: 3fr 5fr;
  grid-template-rows: 1fr;
  transition: all 0.5s ease-out;
}

.grd2-container .item-1 {
  overflow: auto;
  position: relative;
  background-color: #333;
}

.grd2-container .item-2 {
  overflow: auto;
  position: relative;
}

@media only screen and (max-width: 1400px) {
  .grd2-container {
    width: 100%;
    display: block !important;
    grid-template-columns: initial;
    grid-template-rows: initial;
    grid-auto-flow: initial;
  }

  .grd2-container .item-1 {
    width: 100%;
    display: block;
    height: 30vh;
  }

  .grd2-container .item-2 {
    width: 100%;
    display: block;
    height: calc(70vh - 120px);
  }
}

.law-dttb-actions {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
}

svg.MuiSvgIcon-root {
  width: 21px;
  height: auto;
}

div.MuiAccordion-root {
  box-shadow: none !important;
  border-left: 2px solid #999;
}

div.MuiAccordion-root .MuiAccordionSummary-root {
  background-color: #f5f5f5 !important;
}

div.MuiAccordion-root::before {
  background: none !important;
}

.ac-card button.MuiIconButton-root,
.law-modal button.MuiIconButton-root {
  box-shadow: 4px 4px 15px -10px #000;
  background: #fff;
}

div.MuiInputAdornment-root button.MuiIconButton-root,
div.MuiOutlinedInput-root button.MuiIconButton-root,
div.rdt_Table button.MuiIconButton-root,
table button.MuiIconButton-root,
.law-toolbar button.MuiIconButton-root {
  box-shadow: none !important;
  background: none !important;
}


.law-toolbar {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100% !important;
  justify-content: space-between;
  max-width: 100%;
  padding: 3px 15px;
  min-height: 45px;
  align-items: center;
  align-content: center;
  gap: 5px;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 10px;
  background: #f0f4f9;
  border-radius: 40px;
  /*background: linear-gradient(90deg, rgb(109, 0, 136) 0%, rgba(109, 0, 136, 0.1) 50%, rgba(255, 255, 255, 0.1) 90%);*/
}

.law-toolbar>div {
  display: flex;
}

.law-toolbar svg {
  fill: #000 !important;
}

.law-form-grid {
  display: grid;
  gap: 15px;
  max-width: 800px;
  align-items: start;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  justify-content: start;
  margin-top: 15px;
}
.law-form-grid *,
.law-form-grid select{
  margin-bottom: 0 !important;
}

.law-form-grid .law-form-grid {
  margin-top: 0px;
}

@media only screen and (max-width: 640px) {
  .law-form-grid {
    grid-template-columns: 1fr;
  }
}


/**************************************************/
.cmpny-container {
  width: 100%;
  display: block;
}

.cmpny-header {
  padding: 40px 10px;
  width: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

.cmpny-btn-edit {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 36x;
  height: 36px;
  background: #fff;
  border-radius: 36px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cmpny-logo {
  width: 90%;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  padding: 2px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 0px 40px -20px #000;
}

.cmpny-logo img {
  width: 126px;
  height: auto;
  pointer-events: none;
  border-radius: 126px;
  object-fit: contain;
}

.cmpny-header h3,
.cmpny-header span {
  display: block;
  text-align: center;

}

.cmpny-props {
  width: fit-content;
  margin: 0px auto;
  margin-top: 20px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  align-content: center;
  align-items: center;
  overflow: auto;
}

.cmpny-props .item {
  width: 140px;
  min-width: 140px;
  border-radius: 10px;
  margin: 15px 10px;
  padding: 10px 0px;
  border: 1px solid #ddd;
  background: #fff;
}

.cmpny-props .item:hover {
  transform: scale(1.03);
  transition: 0.3s ease-in-out;
}

.cmpny-props .item div {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 15px 0px 5px 0px;
}

.cmpny-props .item div svg {
  width: 32px;
  height: 32px;
  fill: #999;
}

.cmpny-props .item span.title {
  display: block;
  width: 100%;
  color: #999;
  text-align: center;
  font-size: 14px;
}

.cmpny-props .item span.in,
.cmpny-props .item span.out {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: 14px;
}

.cmpny-props .item span.in,
.cmpny-props .item span.total {
  color: orange
}

.cmpny-props .item span.out,
.cmpny-props .item span.total.negative {
  color: #f00
}

.cmpny-props .item span.total {
  font-weight: 600;
  font-size: 16px;
  margin: 10px 5px;
}

.cmpny-props .item strong {
  font-size: 15px;
}

/**************************************************/

.usr-bnnr-container {
  width: 100%;
  display: block;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1)), url(img/accountant-banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.usr-bnnr-user {
  width: fit-content;
  padding: 50px 50px 30px 50px;
}

.usr-bnnr-profile {
  width: 130px;
  height: 130px;
  border-radius: 130px;
  background: #fff;
  margin: auto;
  box-shadow: 0px 0px 40px -20px #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.usr-bnnr-name {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 20px auto;
}

.usr-bnnr-buttons {
  width: fit-content;
  height: 50px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.usr-bnnr-buttons .item {
  width: 36px;
  height: 36px;
  box-shadow: 0px 0px 40px -20px #000;
  background: #fff;
  border-radius: 36px;
  margin: 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .usr-bnnr-user {
    margin: auto;
  }

  .usr-bnnr-buttons {
    position: relative;
    top: initial;
    margin: auto;
    margin-bottom: 50px;
  }
}

/**************************************************/
.tmln-3-container,
.tmln-3-container * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.tmln-3-container {
  width: 96%;
  padding: 30px 0;
  margin: 30px auto;
  position: relative;
  overflow: hidden;
}

.tmln-3-container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #CCD1D9;
  z-index: 1
}

.tmln-3-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.tmln-3-block-right {
  float: right;
}

.tmln-3-block-left {
  float: left;
  direction: rtl
}

.tmln-3-marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  background: #4FC1E9;
  margin-top: 10px;
  z-index: 9999
}

.tmln-3-content {
  width: 95%;
  padding: 0 15px;
  color: #666
}

.tmln-3-content h6 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500
}

.tmln-3-content span {
  font-size: 15px;
  color: #a4a4a4;
}

.tmln-3-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}


@media screen and (max-width: 9960px) {
  .tmln-3-container:before {
    left: 8px;
    width: 2px;
  }

  .tmln-3-block {
    width: 100%;
    margin-bottom: 30px;
  }

  .tmln-3-block-right {
    float: none;
  }

  .tmln-3-block-left {
    float: none;
    direction: ltr;
  }
}

/*******************************************************************/
.mnbtns-area{
  backdrop-filter: blur(5px);
  width: 100%;
  padding:0;
  height: 100vh;
}
.mnbtns-container {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
  
}

  .mnbtns-container .item {
      width: 110px;
      height: 110px;
      background: #fff;
      text-decoration: none;
      margin: 5px;
      border-radius: 15px;
      padding: 3px 10px;
      border: none;
      overflow: hidden;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important
  }

      .mnbtns-container .item.hide {
          display: none;
      }

      .mnbtns-container .item.all-show {
          display: block !important;

      }

      .mnbtns-container .item > div {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 10px 0px;
          position: relative;
      }

      .mnbtns-container .item span {
          display: block;
          width: 100%;
          text-align: center;
          font-weight: 500;
          font-size: 0.85rem;
          line-height: 16px;
      }

      .mnbtns-container .item > div.icon div {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          padding: 0px;
          background: #e3eee6;
          box-shadow: rgb(99, 222, 200) 0px 3px 20px inset;
      }

      .mnbtns-container .item.other,
      .mnbtns-container .item.other *,
      .mnbtns-container .item.other div.icon {
          background: #fff !important;
          color: #000;
          box-shadow: none !important;
      }

          .mnbtns-container .item.other div.icon i:nth-child(1) {
              font-size: 2rem !important;
          }

      .mnbtns-container .item.for-edit > div.icon div {
          margin-top: 0px;
          width: 90px;
          height: 90px;
          box-shadow: none !important;
      }

      .mnbtns-container .item div.icon i:nth-child(1) {
          font-size: 1.2rem;
          color: #2f68b9
      }

      .mnbtns-container .item:hover {
          box-shadow: none !important;
      }

          .mnbtns-container .item:hover,
          .mnbtns-container .item:hover span,
          .mnbtns-container .item:hover div.icon i:nth-child(1) {
              cursor: pointer;
          }

              .mnbtns-container .item:hover div.icon div {
                  box-shadow: none;
              }

              .mnbtns-container .item:hover * {
                  transition-timing-function: linear;
                  transition-delay: 0.1s;
              }

      .mnbtns-container .item div.icon i:nth-child(2) {
          position: absolute;
          font-size: 6rem;
          left: 30%;
          top: 30%;
          font-weight: 900;
          color: #0047ab;
          opacity: 0.03;
      }
      .mnbtns-container .item.all-show,
      .mnbtns-container .item.found-show {
          display: initial !important;
          -webkit-animation: scale-in-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: scale-in-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .mnbtns-container .item.not-found {
          display: none !important
      }
#mnbtns-search-area {
  position:relative;
  padding-right:20%
}
#mnbtns-search-area input {
  background: rgb(255 255 255 / 20%);
  color: #fff;
  padding-left: 30px;
}
#mnbtns-search-area i {
  position: absolute;
  top: calc(50% - 8px);
  left: 10px;
  color: #fff
}
  /*******************************************************************/