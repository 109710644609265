.invoice-template-container {
    max-width: 1000px;
    min-width: 800px;
    width: 100%;
    margin: 20px auto;
    background-color: #fff;
    padding: 15px;
  }
  .invoice-template-container .invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .invoice-template-container .invoice-header .invoice-logo {
    font-size: 24px;
    font-weight: bold;
    color: #0044cc;
  }
  .invoice-template-container .invoice-header .invoice-company-info {
    text-align: right;
  }
  .invoice-template-container .invoice-section {
    margin-bottom: 20px;
  }
  .invoice-template-container .invoice-section h3 {
    margin-bottom: 10px;
    color: #0044cc;
  }
  .invoice-template-container .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
 
  .invoice-template-container .invoice-table th, .invoice-table td {
    /*border: 1px solid #ddd;*/
    padding: 4px;
    text-align: left;
  }
  .invoice-template-container .invoice-table tbody tr:hover td{
    background: #eeeee4 !important;
  }
  .invoice-template-container .invoice-table tbody td:nth-child(2n){
    background-color: rgb(248, 247, 240);
  }
  .invoice-template-container .invoice-table td .MuiOutlinedInput-root{
    border:none !important
  }
  .invoice-template-container .invoice-table th {
    background-color: #f2f2f2;
  }
  .invoice-template-container .invoice-table tfoot {
    font-weight: 700;
  }
  .invoice-template-container .invoice-totals {
    text-align: right !important;
  }
  .invoice-template-container .invoice-totals td {
    font-weight: bold;
  }
  .invoice-template-container .invoice-footer {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }